import moment from "moment";
import { store } from "../redux/stores/store";
import { currencies } from "./constant";

const getLastActiveAtTime = (lastActiveAt) => {
  let jobLastActiveAt = moment(lastActiveAt);
  let timeNow = moment(moment());

  // few minutes ago - less than 1 hour
  // 1 hour ago - more than 1 hour & less than 2 hours
  // few hours ago - more than 2 hours & less than 24 hours
  // few days ago - more than 24 hours & less than 168 hours (1 week)
  // 1 week ago - more than 168 hours (1 week) & less than 336 hours (2 weeks)
  // hidden - more than 336 hours (2 weeks)

  let time =
    timeNow.diff(jobLastActiveAt, "hours") > 168 &&
    timeNow.diff(jobLastActiveAt, "hours") <= 336
      ? "1 week ago"
      : timeNow.diff(jobLastActiveAt, "hours") > 24 &&
        timeNow.diff(jobLastActiveAt, "hours") <= 168
      ? "few days ago"
      : timeNow.diff(jobLastActiveAt, "hours") > 2 &&
        timeNow.diff(jobLastActiveAt, "hours") <= 24
      ? "few hours ago"
      : timeNow.diff(jobLastActiveAt, "hours") > 1 &&
        timeNow.diff(jobLastActiveAt, "hours") <= 2
      ? "1 hour ago"
      : timeNow.diff(jobLastActiveAt, "hours") < 1
      ? "few minutes ago"
      : null;

  return time;
};

function computeScrollSpeed() {
  let lastPos,
    newPos,
    timer,
    delta,
    delay = 50;

  function clear() {
    lastPos = null;
    delta = 0;
  }

  clear();

  return function (cScrollY) {
    newPos = cScrollY;
    if (lastPos != null) {
      delta = newPos - lastPos;
    }
    lastPos = newPos;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
}

const checkScrollSpeed = computeScrollSpeed();

const getSearchBarAndFilterStatus = (cScrollY, threshold) => {
  let searchBarStatus = store.getState().navbar.showSearchBar;
  let scrollY = -1;

  // How fast user scroll
  // TODO - check why speed is always 0
  let speed =
    store.getState().navbar.scrollYWhenSearchBarClicked != 0
      ? checkScrollSpeed(cScrollY)
      : 0;

  // How much user scroll after click on search bar
  let accumulateScroll =
    store.getState().navbar.scrollYWhenSearchBarClicked != 0
      ? Math.abs(store.getState().navbar.scrollYWhenSearchBarClicked - cScrollY)
      : 0;

  // If user scroll more than 80 after clicked on search bar || user scroll speed > 45
  if (accumulateScroll >= 80 || cScrollY < threshold || Math.abs(speed) > 45) {
    scrollY = 0;
    if (isScrollYMoreThanThreshold(cScrollY, threshold)) {
      searchBarStatus = true;
    } else {
      searchBarStatus = false;
    }
  } else {
    if (store.getState().navbar.scrollYWhenSearchBarClicked == 0) {
      if (isScrollYMoreThanThreshold(cScrollY, threshold)) {
        searchBarStatus = true;
      } else {
        searchBarStatus = false;
      }
    }
  }

  return {
    scrollY,
    searchBarStatus,
  };
};

// Update search bar and search filter visibility status according to scrollY
const isScrollYMoreThanThreshold = (cScrollY, threshold) => {
  if (cScrollY >= threshold) {
    return true;
  } else {
    return false;
  }
};

function formatSalary(input, crossBorderData) {
  const geoEnv = process.env.NEXT_PUBLIC_JSW_GEOLOCATION;
  let currency;

  // prioritise source_country as it may be jobs utilising "cross border token"
  // if no source_country, it means the job is from the target geolocation of ur api (e.g. my-api OR sg-api)
  // therefore, fallback is to just use geolocation ENV
  if (crossBorderData?.source_country) {
    currency = currencies[crossBorderData?.source_country];
  } else if (geoEnv === "sg") {
    currency = "SGD";
  } else {
    currency = "RM";
  }

  if (!input?.includes(" - ")) {
    const salaryNumber = Number(input?.replace(currency, ""));
    if (salaryNumber < 10000) {
      return `${currency} ${salaryNumber}`;
    }
    const salaryInK = salaryNumber / 1000;
    const startDecimals = salaryInK?.toFixed(1) % 1 === 0 ? 0 : 1;
    return `${currency} ${salaryInK?.toFixed(startDecimals)}K`;
  }
  const [startSalary, endSalary] = input?.split(" - ");

  const startNumber = Number(startSalary?.replace(currency, ""));

  if (startNumber === 0) {
    return `${endSalary}`;
  }

  const endNumber = Number(endSalary?.replace(currency, ""));

  if (startNumber < 10000 && endNumber < 10000) {
    return `${currency} ${startNumber} - ${currency} ${endNumber}`;
  }

  const startSalaryInK = startNumber / 1000;
  const endSalaryInK = endNumber / 1000;

  const startDecimals = startSalaryInK?.toFixed(1) % 1 === 0 ? 0 : 1;
  const endDecimals = endSalaryInK?.toFixed(1) % 1 === 0 ? 0 : 1;

  return `${currency} ${startSalaryInK?.toFixed(
    startDecimals
  )}K - ${currency} ${endSalaryInK?.toFixed(endDecimals)}K`;
}

export { formatSalary, getLastActiveAtTime, getSearchBarAndFilterStatus };
