import LinkIcon from "@mui/icons-material/Link";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import withTheme from "@mui/styles/withTheme";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import styled, { css } from "styled-components";
import Color from "../../../../assets/colors";
import { FontSize } from "../../../../assets/font";

// react-share Components //
export const EmailShareButtonStyled = withTheme(styled(EmailShareButton)`
  && {
    padding: 5px 8px;
    width: 25%;
  }
`);
export const FacebookShareButtonStyled = withTheme(styled(FacebookShareButton)`
  && {
    padding: 5px 8px;
    width: 25%;
  }
`);
export const LinkedinShareButtonStyled = withTheme(styled(LinkedinShareButton)`
  && {
    padding: 5px 8px;
    width: 25%;
  }
`);
export const TwitterShareButtonStyled = withTheme(styled(TwitterShareButton)`
  && {
    padding: 5px 8px;
    width: 25%;
  }
`);
export const WhatsappShareButtonStyled = withTheme(styled(WhatsappShareButton)`
  && {
    padding: 5px 8px;
    width: 25%;
  }
`);

// Grid Components //
export const ShareIconContainer = withTheme(styled(Grid)`
  && {
    position: absolute;
    z-index: 1;
    top: 8%;
    right: 0;
    top: 0;

    ${(props) =>
      props.tag === "job-list-page" &&
      css`
        right: 5%;
      `};

    ${(props) =>
      props.tag === "single-job-page" &&
      css`
        top: 12%;
        right: 5%;
      `}

    ${(props) => props.theme.breakpoints.up("lg")} {
      ${(props) =>
        props.tag === "single-job-page" &&
        css`
          right: 0;
        `}
    }
  }
`);
export const GridShare = withTheme(styled(Grid)`
  && {
    height: 80%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
`);
export const GridButton = withTheme(styled(Grid)`
  && {
    height: 100%;
    display: flex;
  }
`);
export const GridShareContent = withTheme(styled(Grid)`
  && {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    justify-content: space-around;
  }
`);
export const LinkIconContainer = styled(Grid)`
  && {
    height: 45px;
    width: 45px;
    border-radius: 50px;
    background: ${Color.darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const LinkContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 25%;
    align-items: center;
  }
`;

// Typography Components //
export const Title = withTheme(styled(Typography)`
  && {
    font-weight: ${FontSize.bold};
    max-width: 100%;
    margin: 20px auto;
    color: ${Color.black};
    font-size: ${FontSize.desktopHeadline};
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    text-align: center;
  }
`);
export const LabelStyled = withTheme(styled(Typography)`
  && {
    width: 100%;
    font-weight: ${FontSize.bold};
    margin: ${(props) => (props.$custom ? "16px 0" : "10px 0 18px")};
    color: ${Color.black};
    font-size: ${FontSize.desktopCaption};
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: center;
    padding: ${(props) => (props.$custom ? "0 8px" : "")};
  }
`);

// Icon Components //
export const ShareIconStyled = styled(ShareIcon)`
  && {
    color: ${Color.black};
  }
`;
export const LinkIconStyled = styled(LinkIcon)`
  && {
    font-size: ${FontSize.desktopDisplay3};
    color: ${Color.white};
  }
`;

// Button Components //
export const ShareIconButton = styled(IconButton)`
  && {
    border: none;
  }
`;
export const ButtonStyled = withTheme(styled(Button)`
  && {
    width: 100%;
    border-radius: 30px;
    font-size: ${FontSize.desktopCaption};
    font-weight: ${FontSize.bold};
    background: ${Color.hiredlyPurple};
    color: ${Color.white};
    text-transform: capitalize;
    padding: 10px;
    border: none;
    box-shadow: none;
  }
`);
