import Grid from "@mui/material/Grid";
import { Fragment } from "react";
import { connect } from "react-redux";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { animated, config, useSpring } from "react-spring";
import { bindActionCreators } from "redux";
import Color from "../../../../assets/colors";
import { triggerSnackBar } from "../../../../helpers/snackbar_management";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../../redux/actions/snackbar_action";
import {
  ButtonStyled,
  EmailShareButtonStyled,
  FacebookShareButtonStyled,
  GridButton,
  GridShare,
  GridShareContent,
  LabelStyled,
  LinkContainer,
  LinkIconContainer,
  LinkIconStyled,
  LinkedinShareButtonStyled,
  ShareIconButton,
  ShareIconContainer,
  ShareIconStyled,
  Title,
  TwitterShareButtonStyled,
  WhatsappShareButtonStyled,
} from "./styles";

function MobileShareIcons(props) {
  const {
    job,
    selectedJob,
    onTriggerSocialShare,
    isShareIconsOpen,
    onHandleHide,
  } = props;

  const CopyToClipboard = async () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = shareUrl;
    try {
      await navigator.clipboard.writeText(text);
      let cParams = {
        message: "Link copied successfully",
        severity: "success",
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
    } catch (error) {
      let cParams = {
        message: "Link failed to copy",
        severity: "error",
      };
      triggerSnackBar(props.hideSnackBar, props.showSnackBar, cParams);
    }
  };

  const contentProps = useSpring({
    position: "fixed",
    bottom: isShareIconsOpen ? "0" : "-5%",
    config: isShareIconsOpen ? { ...config.stiff } : { duration: 150 },
    opacity: isShareIconsOpen ? "1" : "0",
    background: Color.white,
    width: "100%",
    zIndex: "22",
    borderRadius: "30px 30px 0 0",
    padding: "20px",
  });

  const overlayProps = useSpring({
    position: "fixed",
    zIndex: "21",
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.4)",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  });

  const AnimatedShareIconsContainer = animated(Grid);
  const AnimatedOverlay = animated(Grid);
  const shareUrl = `${process.env.NEXT_PUBLIC_DOMAIN}/jobs/${selectedJob?.slug}`;
  const title = `I found a company you might like!\n\n${selectedJob?.title}\n\nHave a look here`;

  return (
    <Fragment>
      <ShareIconContainer onClick={onTriggerSocialShare(job)}>
        <ShareIconButton
          aria-label="share"
          size="medium"
          id={"company-link-sharing-button"}
        >
          <ShareIconStyled size="small" />
        </ShareIconButton>
      </ShareIconContainer>

      <Grid>
        {isShareIconsOpen && (
          <Fragment>
            <AnimatedOverlay
              style={overlayProps}
              onClick={onHandleHide}
              id={"menu-list-grow"}
            >
              <AnimatedShareIconsContainer style={contentProps}>
                <Title>Share Company via:</Title>
                <GridShare>
                  <GridShareContent>
                    <FacebookShareButtonStyled
                      url={shareUrl}
                      quote={title}
                      id={"facebook-company-link-sharing-button"}
                    >
                      <FacebookIcon size={45} round />
                      <LabelStyled>Facebook</LabelStyled>
                    </FacebookShareButtonStyled>
                    <TwitterShareButtonStyled
                      url={shareUrl}
                      title={title}
                      id={"twitter-company-link-sharing-button"}
                    >
                      <TwitterIcon size={45} round />
                      <LabelStyled>Twitter</LabelStyled>
                    </TwitterShareButtonStyled>
                    <LinkedinShareButtonStyled
                      url={shareUrl}
                      id={"linkedin-company-link-sharing-button"}
                    >
                      <LinkedinIcon size={45} round />
                      <LabelStyled>Linkedin</LabelStyled>
                    </LinkedinShareButtonStyled>
                  </GridShareContent>
                  <GridShareContent>
                    <WhatsappShareButtonStyled
                      url={shareUrl}
                      title={title}
                      separator=": "
                      id={"whatsapp-company-link-sharing-button"}
                    >
                      <WhatsappIcon size={45} round />
                      <LabelStyled>Whatsapp</LabelStyled>
                    </WhatsappShareButtonStyled>
                    <EmailShareButtonStyled
                      url={shareUrl}
                      subject={title}
                      id={"email-company-link-sharing-button"}
                    >
                      <EmailIcon size={45} round />
                      <LabelStyled>Email</LabelStyled>
                    </EmailShareButtonStyled>
                    <LinkContainer>
                      <LinkIconContainer
                        onClick={CopyToClipboard}
                        id={"copy-company-link-sharing-button"}
                      >
                        <LinkIconStyled />
                      </LinkIconContainer>
                      <LabelStyled $custom>Link</LabelStyled>
                    </LinkContainer>
                  </GridShareContent>

                  <GridButton>
                    <ButtonStyled
                      variant="contained"
                      color="primary"
                      onClick={onHandleHide}
                    >
                      Cancel
                    </ButtonStyled>
                  </GridButton>
                </GridShare>
              </AnimatedShareIconsContainer>
            </AnimatedOverlay>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(MobileShareIcons);
