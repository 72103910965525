import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import {
  JobBannerShareIconSkeleton,
  JobBannerLogoSkeleton,
  JobBannerButtonSkeleton,
  JobBannerJobTitleSkeleton,
  CompanyNameLocationSkeleton,
  JobBannerCompanyNameSkeleton,
  JobBannerLocationSkeleton,
  NavigationContainerSkeleton,
  GridStyled,
  JobInfoTitle,
  JobInfoDescription,
  JobInfoSkeletonWrapper,
  JobInfoSkeletonContainer,
  CompanyBannerImageSkeleton,
  CompanyBannerLogoSkeleton,
  CompanyBannerButtonSkeleton,
  CompanyBannerTextContainer,
  CompanyBannerIndustrySkeleton,
  CompanyBannerJobCountSkeleton,
  CompanyInfoJobDescriptionContainer,
  CompanyInfoTitleSkeleton,
  CompanyInfoDescriptionSkeleton,
  OurPeopleTitleSkeleton,
  OurPeopleVideoSkeleton,
  OurPeopleTextContainer,
  OurPeopleDescriptionSkeleton,
  OurPeopleCaptionSkeleton,
  OurBenefitSlotSkeleton,
  OurMediaImagesSkeleton,
  OurOfficeMapSkeleton,
  OurOfficeTextSkeleton,
  OurJobTitleSkeleton,
  OurJobFilterSkeleton,
  OurJobCardSkeleton,
  OurMoreTitleSkeleton,
  OurMoreVideoSkeleton,
  PaginationCardSkeleton,
  CompanyInfoContainerSkeleton,
  OurPeopleContainerSkeleton,
  OurBenefitContainerSkeleton,
  OurMediaContainerSkeleton,
  OurOfficeContainerSkeleton,
  OurJobsContainerSkeleton,
  OurMoreVideoContainerSkeleton,
  OurOfficeContainer,
  NavigationWrapper,
  JobDescriptionContainer,
  CompanyBannerContainer,
  OurOfficeWrapper,
  JobBannerContainer,
  RecommendedJobsContainerSkeleton,
  RecommendedJobsSliderSkeleton,
  PaginationPageNumbersContainerSkeleton,
  PaginationPageNumbersSkeleton,
  CompanyBannerTitleContainer,
  CompanyBannerJobDaysAgo,
  CompanyBannerSaveButtonSkeleton,
  CompanyBannerDescSkeleton,
  CompanyInfoDescriptionTitleSkeleton,
  CompanyInfoTitleDescriptionContainer,
  CompanyJobRequirementTitleSkeleton,
  CompanyJobRequirementDescSkeleton,
  CompanyJobRequirementDescTitleSkeleton,
  CompanyBenefitTitleSkeleton,
  CompanyBenefitCardeSkeleton,
  CompanyProfileTitleSkeleton,
  CompanyProfileCardeSkeleton,
  CompanyOfficeTitleSkeleton,
  CompanyOfficeCardeSkeleton,
  CompanyBannerJobNameSkeleton,
  CompanyBannerButtonsSkeleton,
  CompanyBannerTabletSaveSkeleton,
  CompanyBannerTabletApplySkeleton,
  CompanyBannerJobDetailSkeleton,
  ProgressIndicatorStyled,
  JobHighlightSkeleton,
  AiSummarySkeleton,
  JobContentSkeletonContainer,
  AiSummarySkeletonContainer,
  SecondaryTextSkeleton,
  AiSummaryBox,
  ProfileImageSkeleton,
  TitleSkeleton,
  PrimaryTextSkeleton,
  AiJobSummaryEmptyImageSkeleton,
  AiJobSummaryEmptySkeletonContainer,
  JobCard,
  JobDetailsBottom,
  JobDetailsTop,
  TopContentLeft,
  TopContentRight,
  EmptyContainer,
  SkeletonStyled,
} from "./styles";

export function JobBannerSkeleton(props) {
  const { tag } = props;

  return (
    <JobBannerContainer container>
      {/* Job banner share icon skeleton */}
      {tag === "single-job-page" ? null : (
        <JobBannerShareIconSkeleton
          variant="circle"
          width={60}
          height={60}
          animation="wave"
        />
      )}

      {/* Company logo skeleton */}
      {tag === "single-job-page" ? null : (
        <Grid>
          <JobBannerLogoSkeleton variant="rectangular" animation="wave" />
        </Grid>
      )}

      {/* Job banner button skeleton */}
      {tag === "single-job-page" ? null : (
        <Grid container>
          <JobBannerButtonSkeleton
            variant="rectangular"
            width={"13%"}
            height={"50px"}
            animation="wave"
          />
          <JobBannerButtonSkeleton
            variant="rectangular"
            width={"13%"}
            height={"50px"}
            animation="wave"
            $right={"4%"}
          />
        </Grid>
      )}

      {/* Job banner text skeleton */}
      {tag === "single-job-page" ? null : (
        <Grid>
          <JobBannerJobTitleSkeleton
            variant="rectangular"
            width={"35%"}
            height={"3vh"}
            animation="wave"
          />

          <CompanyNameLocationSkeleton container>
            <JobBannerCompanyNameSkeleton
              variant="rectangular"
              width={"12%"}
              height={"3vh"}
              animation="wave"
            />
            <JobBannerLocationSkeleton
              variant="rectangular"
              width={"12%"}
              height={"3vh"}
              animation="wave"
            />
          </CompanyNameLocationSkeleton>
        </Grid>
      )}
    </JobBannerContainer>
  );
}

export function NavigationBarSkeleton(props) {
  return (
    <Fragment>
      <NavigationWrapper container>
        <NavigationContainerSkeleton
          variant="rectangular"
          width={"100%"}
          height={"70px"}
          animation="wave"
        />
      </NavigationWrapper>
    </Fragment>
  );
}

export function JobInfoSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <JobInfoSkeletonWrapper
        container
        justifyContent="space-between"
        alignItems="stretch"
        tag={tag}
      >
        <JobInfoSkeletonContainer tag={tag}>
          {Array.from(new Array("0", "1")).map((index) => {
            return (
              <Grid key={index}>
                <JobInfoTitle
                  variant="rectangular"
                  width={"100%"}
                  height={"4vh"}
                  animation="wave"
                />
                <JobDescriptionContainer>
                  <JobInfoDescription
                    variant="rectangular"
                    width={"100%"}
                    height={"20vh"}
                    animation="wave"
                  />
                </JobDescriptionContainer>
              </Grid>
            );
          })}
        </JobInfoSkeletonContainer>
      </JobInfoSkeletonWrapper>
    </Fragment>
  );
}

export function CompanyBannerProgressSkeleton(props) {
  const { companyInfo } = props;

  return (
    <CompanyBannerJobDetailSkeleton
      $alignCenter
      $flex
      $companyInfo={companyInfo}
    >
      <ProgressIndicatorStyled />
    </CompanyBannerJobDetailSkeleton>
  );
}

export function CompanyBannerSkeleton(props) {
  const { tag } = props;

  return (
    <CompanyBannerContainer>
      {/* Company banner skeleton */}
      <CompanyBannerImageSkeleton
        variant="rectangular"
        width={"100%"}
        height={"40vh"}
        animation="wave"
      />

      {/* Company logo skeleton */}
      {tag == "single-job-page" ? null : (
        <Grid>
          <CompanyBannerLogoSkeleton
            variant="rectangular"
            width={"84px"}
            height={"84px"}
            animation="wave"
          />
        </Grid>
      )}

      {/* Company banner text skeleton */}
      {tag == "single-job-page" ? null : (
        <Grid>
          <CompanyBannerTitleContainer container>
            {/* Job listing title section */}
            <CompanyBannerJobNameSkeleton
              variant="rectangular"
              width={"40%"}
              height={"40px"}
              animation="wave"
            />
            <CompanyBannerButtonSkeleton
              variant="rectangular"
              width={"13%"}
              height={"40px"}
              animation="wave"
            />
            <CompanyBannerSaveButtonSkeleton
              variant="circle"
              width={"38px"}
              height={"38px"}
              animation="wave"
            />
          </CompanyBannerTitleContainer>

          {/* Location, Job type, salary and date posted section */}
          <CompanyBannerTextContainer container>
            <CompanyBannerIndustrySkeleton
              variant="rectangular"
              width={"13%"}
              height={"3vh"}
              animation="wave"
            />
            <CompanyBannerJobCountSkeleton
              variant="rectangular"
              width={"13%"}
              height={"3vh"}
              animation="wave"
            />
            <CompanyBannerJobCountSkeleton
              variant="rectangular"
              width={"13%"}
              height={"3vh"}
              animation="wave"
            />
            <CompanyBannerJobDaysAgo
              variant="rectangular"
              width={"13%"}
              height={"3vh"}
              animation="wave"
            />
          </CompanyBannerTextContainer>
          <CompanyBannerButtonsSkeleton container>
            <CompanyBannerTabletApplySkeleton
              variant="rectangular"
              width={"15%"}
              height={"3vh"}
              animation="wave"
            />
            <CompanyBannerTabletSaveSkeleton
              variant="rectangular"
              width={"13%"}
              height={"3vh"}
              animation="wave"
            />
          </CompanyBannerButtonsSkeleton>
        </Grid>
      )}
    </CompanyBannerContainer>
  );
}

export function CompanyInfoSkeleton(props) {
  const { tag } = props;

  return (
    <CompanyInfoContainerSkeleton container justify={"space-evenly"} tag={tag}>
      <CompanyBannerDescSkeleton
        variant="rectangular"
        width={"100%"}
        height={"150vh"}
        animation="wave"
      />

      {/* Job description section */}
      <CompanyInfoJobDescriptionContainer>
        <CompanyInfoTitleSkeleton
          variant="rectangular"
          width={"30%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyInfoDescriptionTitleSkeleton
          variant="rectangular"
          width={"25%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyInfoDescriptionSkeleton
          variant="rectangular"
          width={"60%"}
          height={"3vh"}
          animation="wave"
        />
      </CompanyInfoJobDescriptionContainer>

      {/* Job Requirement section */}
      <CompanyInfoTitleDescriptionContainer>
        <CompanyJobRequirementTitleSkeleton
          variant="rectangular"
          width={"30%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyJobRequirementDescTitleSkeleton
          variant="rectangular"
          width={"25%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyJobRequirementDescSkeleton
          variant="rectangular"
          width={"60%"}
          height={"3vh"}
          animation="wave"
        />
      </CompanyInfoTitleDescriptionContainer>

      {/* Company benefit section */}
      <CompanyInfoTitleDescriptionContainer>
        <CompanyBenefitTitleSkeleton
          variant="rectangular"
          width={"30%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyBenefitCardeSkeleton
          variant="rectangular"
          width={"50%"}
          height={"20vh"}
          animation="wave"
        />
      </CompanyInfoTitleDescriptionContainer>

      {/* Company profile section */}
      <CompanyInfoTitleDescriptionContainer>
        <CompanyProfileTitleSkeleton
          variant="rectangular"
          width={"30%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyProfileCardeSkeleton
          variant="rectangular"
          width={"90%"}
          height={"22vh"}
          animation="wave"
        />
      </CompanyInfoTitleDescriptionContainer>

      {/* Company Office Image section */}
      <CompanyInfoTitleDescriptionContainer>
        <CompanyOfficeTitleSkeleton
          variant="rectangular"
          width={"30%"}
          height={"3vh"}
          animation="wave"
        />
        <CompanyOfficeCardeSkeleton
          variant="rectangular"
          width={"90%"}
          height={"22vh"}
          animation="wave"
        />
      </CompanyInfoTitleDescriptionContainer>
    </CompanyInfoContainerSkeleton>
  );
}

export function OurPeopleSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurPeopleContainerSkeleton container tag={tag}>
        <OurPeopleTitleSkeleton
          variant="rectangular"
          width={"100vw"}
          height={"4vh"}
          animation="wave"
        />
        {Array.from(new Array("0", "1")).map((index) => {
          return (
            <GridStyled
              container
              $reverse={index % 2 !== 0}
              justify="space-evenly"
              key={index}
            >
              <OurPeopleVideoSkeleton
                variant="rectangular"
                height={"40vh"}
                animation="wave"
              />
              <OurPeopleTextContainer>
                <Grid>
                  {Array.from(new Array("0", "1", "2")).map((index) => {
                    return (
                      <Grid key={index + 1}>
                        <OurPeopleDescriptionSkeleton
                          width={"auto"}
                          variant="text"
                          height={"5vh"}
                          animation="wave"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid>
                  <OurPeopleCaptionSkeleton
                    variant="text"
                    height={"5vh"}
                    animation="wave"
                  />
                  <OurPeopleCaptionSkeleton
                    variant="text"
                    height={"5vh"}
                    animation="wave"
                    $max_width
                  />
                </Grid>
              </OurPeopleTextContainer>
            </GridStyled>
          );
        })}
      </OurPeopleContainerSkeleton>
    </Fragment>
  );
}

export function OurBenefitSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurBenefitContainerSkeleton container tag={tag}>
        <Grid container justify="space-between">
          <OurBenefitSlotSkeleton
            variant="rectangular"
            height={"30vh"}
            width={"100%"}
            animation="wave"
          />
        </Grid>
      </OurBenefitContainerSkeleton>
    </Fragment>
  );
}

export function OurMediaSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurMediaContainerSkeleton container tag={tag}>
        <Grid container>
          <OurMediaImagesSkeleton
            variant="rectangular"
            height={"40vh"}
            animation="wave"
          />
        </Grid>
      </OurMediaContainerSkeleton>
    </Fragment>
  );
}

export function OurOfficeSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurOfficeContainerSkeleton container tag={tag}>
        {Array.from(new Array("0")).map((index) => {
          return (
            <OurOfficeContainer container key={index}>
              <OurOfficeMapSkeleton
                variant="rectangular"
                height={"40vh"}
                animation="wave"
              />
              <OurOfficeWrapper>
                <Grid>
                  {Array.from(new Array("0", "1", "2")).map((index) => {
                    return (
                      <Grid key={index + 1}>
                        <OurOfficeTextSkeleton
                          width={"auto"}
                          variant="text"
                          height={"5vh"}
                          animation="wave"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </OurOfficeWrapper>
            </OurOfficeContainer>
          );
        })}
      </OurOfficeContainerSkeleton>
    </Fragment>
  );
}

export function OurJobSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurJobsContainerSkeleton container tag={tag}>
        <OurJobTitleSkeleton
          variant="rectangular"
          width={"40vw"}
          height={"5vh"}
          animation="wave"
        />
        <OurJobFilterSkeleton
          variant="rectangular"
          width={"100vw"}
          height={"6vh"}
          animation="wave"
        />
        {Array.from(new Array("0")).map((index) => {
          return (
            <Grid container justify="space-evenly" key={index}>
              {Array.from(new Array("0", "1", "2", "3", "4", "5")).map(
                (index) => {
                  return (
                    <Grid key={index + 1}>
                      <OurJobCardSkeleton
                        variant="rectangular"
                        height={"30vh"}
                        animation="wave"
                      />
                    </Grid>
                  );
                }
              )}
            </Grid>
          );
        })}
      </OurJobsContainerSkeleton>
    </Fragment>
  );
}

export function OurMoreSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <OurMoreVideoContainerSkeleton container tag={tag}>
        <OurMoreTitleSkeleton
          variant="rectangular"
          width={"100%"}
          height={"4vh"}
          animation="wave"
        />
        <Grid container>
          <OurMoreVideoSkeleton
            variant="rectangular"
            height={"40vh"}
            animation="wave"
          />
        </Grid>
      </OurMoreVideoContainerSkeleton>
    </Fragment>
  );
}

export function PaginationSkeleton(props) {
  return (
    <Fragment>
      <Grid container>
        {Array.from(new Array("0", "1", "2", "3", "4")).map((index) => {
          return (
            <PaginationCardSkeleton
              variant="rectangular"
              width={"100%"}
              animation="wave"
              key={index}
            />
          );
        })}
      </Grid>
    </Fragment>
  );
}

export function RecommendedJobsSkeleton(props) {
  const { tag } = props;

  return (
    <Fragment>
      <RecommendedJobsContainerSkeleton container tag={tag}>
        <RecommendedJobsSliderSkeleton
          variant="rectangular"
          height={"30vh"}
          width={"100vw"}
          animation="wave"
        />
      </RecommendedJobsContainerSkeleton>
    </Fragment>
  );
}

export function PaginationPagesSkeleton(props) {
  const { tag } = props;

  return (
    <PaginationPageNumbersContainerSkeleton>
      <PaginationPageNumbersSkeleton
        variant="rectangular"
        width={tag === "mobile" ? "90%" : "85%"}
        height={"32px"}
        animation="wave"
      />
    </PaginationPageNumbersContainerSkeleton>
  );
}

export function JobContentSkeleton({ width }) {
  return (
    <JobContentSkeletonContainer>
      <div>
        <AiSummarySkeleton>
          <ProgressIndicatorStyled />
        </AiSummarySkeleton>
        <CompanyBannerProgressSkeleton companyInfo={true} />
      </div>
      {width >= 768 && (
        <div>
          <JobHighlightSkeleton>
            <ProgressIndicatorStyled />
          </JobHighlightSkeleton>
        </div>
      )}
    </JobContentSkeletonContainer>
  );
}

export function AiJobSummarySkeleton(props) {
  return (
    <AiSummarySkeletonContainer>
      <SecondaryTextSkeleton variant="rounded" width={154} height={24} />
      <AiSummaryBox>
        <ProfileImageSkeleton variant="circular" width={56} height={56} />
        <TitleSkeleton variant="rounded" height={40} />
      </AiSummaryBox>
      <SecondaryTextSkeleton variant="rounded" width={154} height={24} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" height={21} />
      <PrimaryTextSkeleton variant="rounded" width={180} height={21} />
    </AiSummarySkeletonContainer>
  );
}

export function AiJobSummaryEmptySkeleton(props) {
  return (
    <AiJobSummaryEmptySkeletonContainer>
      <AiJobSummaryEmptyImageSkeleton
        variant="rectangular"
        width={90}
        height={132}
      />
      <PrimaryTextSkeleton variant="rounded" width={200} height={21} />
    </AiJobSummaryEmptySkeletonContainer>
  );
}

export function AccordionJobListSkeleton(props) {
  return (
    <JobCard container direction="column" justifyContent="space-between">
      <JobDetailsTop container direction="row">
        <TopContentLeft
          container
          justifyContent="space-between"
          direction="column"
        >
          {/* Badges */}
          <EmptyContainer container direction="row" gap="20px">
            <SkeletonStyled variant="rounded" width="10%" />
            <SkeletonStyled variant="rounded" width="15%" />
          </EmptyContainer>
          <SkeletonStyled
            variant="rounded"
            width="55%"
            sx={{ borderRadius: "10px" }}
          />
          <EmptyContainer container direction="row" gap="20px">
            <SkeletonStyled
              variant="rounded"
              width="30%"
              sx={{ borderRadius: "10px" }}
            />
            <SkeletonStyled
              variant="rounded"
              width="20%"
              sx={{ borderRadius: "10px" }}
            />
          </EmptyContainer>
        </TopContentLeft>
        <TopContentRight>
          {/* Bookmarked */}
          <EmptyContainer
            container
            justifyContent="flex-end"
            sx={{ height: "25%" }}
          >
            <SkeletonStyled variant="rounded" width="5%" height="30px" />
          </EmptyContainer>
          {/* Image */}
          <EmptyContainer
            container
            direction="row"
            alignItems="center"
            gap="20px"
            sx={{ height: "75%" }}
          >
            <SkeletonStyled variant="rounded" width="50px" height="50px" />
            <EmptyContainer
              container
              justifyContent="space-between"
              width="50%"
              direction="column"
              gap="10px"
            >
              <SkeletonStyled
                variant="rounded"
                width="80%"
                height="25px"
                sx={{ borderRadius: "10px" }}
              />
              <SkeletonStyled
                variant="rounded"
                width="100%"
                sx={{ borderRadius: "10px" }}
              />
            </EmptyContainer>
          </EmptyContainer>
        </TopContentRight>
      </JobDetailsTop>
      <JobDetailsBottom
        container
        justifyContent="space-between"
        direction="row"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <EmptyContainer
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: "70%", height: "100%" }}
          gap="10px"
        >
          <SkeletonStyled
            variant="rounded"
            width="15%"
            height="15px"
            sx={{ borderRadius: "10px" }}
          />
          <SkeletonStyled variant="rounded" width="10%" height="15px" />
          <SkeletonStyled variant="rounded" width="10%" height="15px" />
          <SkeletonStyled variant="rounded" width="10%" height="15px" />
          <SkeletonStyled variant="rounded" width="10%" height="15px" />
        </EmptyContainer>
        <EmptyContainer
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ width: "15%", height: "100%" }}
        >
          <SkeletonStyled variant="rounded" width="50%" height="15px" />
        </EmptyContainer>
      </JobDetailsBottom>
    </JobCard>
  );
}
